export const flowStart = () => {
  return;
};

export const visitedPage = (pathname: string, search: string) => {
  return;
};

export const flowApproved = () => {
  return;
};

export const flowDeclined = () => {
  return;
};

export const flowResubmission = () => {
  return;
};

export const flowUploadLoader = () => {
  return;
};

export const flowError = () => {
  return;
};
