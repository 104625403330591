<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="3" width="35" height="35" rx="17.5" fill="var(--general-colors-primary)" />
  <rect
    x="3"
    y="3"
    width="35"
    height="35"
    rx="17.5"
    stroke="var(--general-colors-primary)"
    stroke-opacity="0.2"
    stroke-width="5"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.8167 16.8333C12.8167 15.3973 13.9807 14.2333 15.4167 14.2333H25.5833C27.0193 14.2333 28.1833 15.3973 28.1833 16.8333V24.1666C28.1833 25.6026 27.0193 26.7666 25.5833 26.7666H15.4167C13.9807 26.7666 12.8167 25.6026 12.8167 24.1666V16.8333ZM15.4167 15.4333C14.6435 15.4333 14.0167 16.0601 14.0167 16.8333V24.1666C14.0167 24.9398 14.6435 25.5666 15.4167 25.5666H25.5833C26.3565 25.5666 26.9833 24.9398 26.9833 24.1666V16.8333C26.9833 16.0601 26.3565 15.4333 25.5833 15.4333H15.4167ZM16.0042 19.4374C16.0042 18.3237 16.907 17.4208 18.0208 17.4208C19.1346 17.4208 20.0375 18.3237 20.0375 19.4374C20.0375 19.9311 19.8601 20.3834 19.5656 20.7339C20.2785 21.2254 20.7458 22.0477 20.7458 22.9791C20.7458 23.3105 20.4772 23.5791 20.1458 23.5791C19.8144 23.5791 19.5458 23.3105 19.5458 22.9791C19.5458 22.1369 18.8631 21.4541 18.0208 21.4541C17.1786 21.4541 16.4958 22.1369 16.4958 22.9791C16.4958 23.3105 16.2272 23.5791 15.8958 23.5791C15.5644 23.5791 15.2958 23.3105 15.2958 22.9791C15.2958 22.0477 15.7631 21.2254 16.4761 20.7339C16.1815 20.3834 16.0042 19.9311 16.0042 19.4374ZM18.0208 18.6208C17.5698 18.6208 17.2042 18.9864 17.2042 19.4374C17.2042 19.8885 17.5698 20.2541 18.0208 20.2541C18.4719 20.2541 18.8375 19.8885 18.8375 19.4374C18.8375 18.9864 18.4719 18.6208 18.0208 18.6208ZM21.3166 19.0833C21.3166 18.7519 21.5853 18.4833 21.9166 18.4833H24.75C25.0814 18.4833 25.35 18.7519 25.35 19.0833C25.35 19.4146 25.0814 19.6833 24.75 19.6833H21.9166C21.5853 19.6833 21.3166 19.4146 21.3166 19.0833ZM22.625 21.3166C22.2936 21.3166 22.025 21.5852 22.025 21.9166C22.025 22.248 22.2936 22.5166 22.625 22.5166H24.75C25.0814 22.5166 25.35 22.248 25.35 21.9166C25.35 21.5852 25.0814 21.3166 24.75 21.3166H22.625Z"
    fill="white"
  />
</svg>
