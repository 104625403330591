<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="3" width="35" height="35" rx="17.5" fill="var(--general-colors-primary)" />
  <rect
    x="3"
    y="3"
    width="35"
    height="35"
    rx="17.5"
    stroke="var(--general-colors-primary)"
    stroke-opacity="0.2"
    stroke-width="5"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16 13H25C26.1046 13 27 13.8954 27 15V26C27 27.1046 26.1046 28 25 28H16C14.8954 28 14 27.1046 14 26V15C14 13.8954 14.8954 13 16 13ZM13 15C13 13.3431 14.3431 12 16 12H25C26.6569 12 28 13.3431 28 15V26C28 27.6569 26.6569 29 25 29H16C14.3431 29 13 27.6569 13 26V15ZM19.3626 17.7811C19.5921 17.3986 19.8307 17.1657 20.05 17.0363V20.05H18.7152C18.7773 19.1333 19.0253 18.3432 19.3626 17.7811ZM19.0297 16.7251C18.3606 17.4506 17.8964 18.6568 17.8134 20.05H16.4747C16.6429 18.5288 17.6535 17.2615 19.0297 16.7251ZM19.0297 24.2749C18.3606 23.5494 17.8964 22.3432 17.8134 20.95H16.4747C16.6429 22.4712 17.6535 23.7385 19.0297 24.2749ZM19.3626 23.2189C19.5921 23.6014 19.8307 23.8343 20.05 23.9637V20.95H18.7152C18.7773 21.8667 19.0253 22.6568 19.3626 23.2189ZM24.5253 20.95C24.3571 22.4711 23.3465 23.7384 21.9704 24.2748C22.6395 23.5493 23.1037 22.3431 23.1867 20.95H24.5253ZM21.6375 23.2189C21.9748 22.6568 22.2228 21.8667 22.2849 20.95H20.95L20.95 23.9638C21.1693 23.8344 21.408 23.6015 21.6375 23.2189ZM24.5253 20.05H23.1867C23.1037 18.6569 22.6395 17.4507 21.9704 16.7252C23.3465 17.2616 24.3571 18.5289 24.5253 20.05ZM21.6375 17.7811C21.408 17.3985 21.1693 17.1656 20.95 17.0362L20.95 20.05H22.2849C22.2228 19.1333 21.9748 18.3432 21.6375 17.7811ZM20.5 15.55C17.7662 15.55 15.55 17.7662 15.55 20.5C15.55 23.2338 17.7662 25.45 20.5 25.45C23.2338 25.45 25.45 23.2338 25.45 20.5C25.45 17.7662 23.2338 15.55 20.5 15.55Z"
    fill="white"
  />
</svg>
