<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="3" width="35" height="35" rx="17.5" fill="var(--general-colors-primary)" />
  <rect
    x="3"
    y="3"
    width="35"
    height="35"
    rx="17.5"
    stroke="var(--general-colors-primary)"
    stroke-opacity="0.2"
    stroke-width="5"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.825 17.0589C12.8195 17.1427 12.8167 17.2273 12.8167 17.3125V23.6875C12.8167 25.7793 14.5124 27.475 16.6042 27.475H24.3959C26.4876 27.475 28.1834 25.7793 28.1834 23.6875V17.3125C28.1834 17.2272 28.1805 17.1425 28.175 17.0586C28.1805 17.026 28.1833 16.9925 28.1833 16.9583C28.1833 16.8782 28.1676 16.8018 28.1391 16.7319C27.8598 14.9158 26.2902 13.525 24.3959 13.525H16.6042C14.7099 13.525 13.1405 14.9156 12.8609 16.7316C12.8324 16.8016 12.8167 16.8781 12.8167 16.9583C12.8167 16.9926 12.8195 17.0262 12.825 17.0589ZM14.0167 17.5583V23.6875C14.0167 25.1165 15.1752 26.275 16.6042 26.275H24.3959C25.8249 26.275 26.9834 25.1165 26.9834 23.6875V17.5583H14.0167ZM26.8017 16.3583C26.4219 15.4015 25.4879 14.725 24.3959 14.725H16.6042C15.5121 14.725 14.5781 15.4015 14.1983 16.3583H26.8017ZM18.4834 20.5C18.4834 20.1686 18.752 19.9 19.0834 19.9H24.75C25.0814 19.9 25.35 20.1686 25.35 20.5C25.35 20.8314 25.0814 21.1 24.75 21.1H19.0834C18.752 21.1 18.4834 20.8314 18.4834 20.5ZM19.0834 22.7333C18.752 22.7333 18.4834 23.002 18.4834 23.3333C18.4834 23.6647 18.752 23.9333 19.0834 23.9333H24.75C25.0814 23.9333 25.35 23.6647 25.35 23.3333C25.35 23.002 25.0814 22.7333 24.75 22.7333H19.0834ZM15.6499 20.5C15.6499 20.1686 15.9186 19.9 16.2499 19.9H16.9583C17.2896 19.9 17.5583 20.1686 17.5583 20.5C17.5583 20.8314 17.2896 21.1 16.9583 21.1H16.2499C15.9186 21.1 15.6499 20.8314 15.6499 20.5ZM16.2499 22.7333C15.9186 22.7333 15.6499 23.002 15.6499 23.3333C15.6499 23.6647 15.9186 23.9333 16.2499 23.9333H16.9583C17.2896 23.9333 17.5583 23.6647 17.5583 23.3333C17.5583 23.002 17.2896 22.7333 16.9583 22.7333H16.2499Z"
    fill="white"
  />
</svg>
